/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

function Seo({ postInfo, post }) {
    const siteData = useStaticQuery(
        graphql`
            query seo {
                site {
                    siteMetadata {
                        siteUrl
                        title
                        description
                        defaultImage
                        author
                    }
                }
            }
        `
    )

    const site = {
        siteUrl: siteData.site.siteMetadata.siteUrl,
        title: siteData.site.siteMetadata.title,
        description: siteData.site.siteMetadata.description,
        defaultImage: siteData.site.siteMetadata.defaultImage,
        author: siteData.site.siteMetadata?.author
    }

    const metaTags = {
        title: post.title.replace('<span>', '').replace('</span>', ''),
        description: post.description || siteData.site.siteMetadata.description,
        slug: postInfo.slug,
        canonical: post.canonical || postInfo.canonical,
        lang: 'en'
    }

    const robots = {
        indexStatus: post.indexStatus || 'index',
        followStatus: post.followStatus || 'follow'
    }

    return (
        <Helmet
            htmlAttributes={{
                lang: metaTags.lang
            }}
            title={metaTags.title}
            titleTemplate={site.title ? `%s | ${site.title}` : null}
            meta={[
                {
                    name: 'robots',
                    content: `${robots.indexStatus}, ${robots.followStatus}`
                },
                {
                    name: `description`,
                    content: metaTags.description
                },
                {
                    name: `author`,
                    content: site.author
                },
                {
                    property: `og:title`,
                    content: metaTags.title
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:image`,
                    content: `${site.siteUrl}${site.defaultImage}`
                },
                {
                    property: `og:url`,
                    content: `${site.siteUrl}${metaTags.slug}`
                },
                {
                    property: `og:description`,
                    content: metaTags.description
                },
                {
                    property: `og:site_name`,
                    content: site.title
                },
                {
                    property: `fb:app_id`,
                    content: `1507219169577312`
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`
                },
                {
                    name: `twitter:title`,
                    content: metaTags.title
                },
                {
                    name: `twitter:description`,
                    content: metaTags.description
                },
                {
                    name: `facebook-domain-verification`,
                    content: `govlait4lqkdgq23gx064z5463feuy`
                }
            ]}
            link={[
                {
                    rel: 'canonical',
                    href: `${site.siteUrl}${metaTags.canonical}`
                }
            ]}
        />
    )
}

Seo.defaultProps = {
    post: PropTypes.shape({
        indexStatus: 'index',
        followStatus: 'follow'
    })
}

Seo.propTypes = {
    post: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        canonical: PropTypes.string,
        indexStatus: PropTypes.oneOf(['index', 'noindex']),
        followStatus: PropTypes.oneOf(['follow', 'nofollow'])
    })
}

export default Seo
